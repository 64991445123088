import React, { useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useCurate } from '@hooks/curate/useCurate';
import { useDrag } from '@root/context/DragContext/useDrag';
import EraserTool from './EraserTool';
import LassoTool from './LassoTool';
import { BrushDrawingSettings, BrushTool } from './BrushTool';
import CreateNewSketchModal from './CreateNewSketchModal';
import BorderRadiusFilter from '@root/assets/filters';
import { useStyleDrive } from '@root/context/StyleDriveContext/useStyleDrive';
import { useMixImage } from '@root/context/MixImageContext/useMixImage';
import { CurateTools } from '@root/utils/constants/enums';
import { CommonConstants } from '@root/utils/constants';
import { ImageHelpers } from '@root/utils/helpers';
import { useSceneShift } from '@root/context/SceneShiftContext/useSceneShift';
import EraserSettingsPanel from '@root/components/EraserSettingsPanel';

const CuratedCanvas = () => {
    const imageRef = useRef(null);
    const wrapperRef = useRef(null);
    const zoomWrapperRef = useRef(null);

    const observer = new ResizeObserver(() => setSizes());

    const { activeImageUrlSD, activeImageLayerIdSD } = useStyleDrive();
    const { activeImageUrlMix, activeImageLayerIdMix } = useMixImage();
    const { activeImageUrlSceneShift, activeImageLayerIdSceneShift } = useSceneShift();

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [transform, setTransform] = useState({
        scale: 1,
        positionX: 0,
        positionY: 0,
    });

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const { setDraggedImage } = useDrag();

    const [modalState, setModalState] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [backgroundSketch, setBackgroundSketch] = useState('');

    const {
        activeTool,
        toggleTool,
        canvasImageSrc,
        toggleMetadataDialog,
        isMetadataDialogActive,
        selectLayerByOrderIndex,
        layers,
        canvasImageSketchSrc,
        setActiveImageDimensions,
        eraserToolSize,
        setEraserToolSize,
    } = useCurate();

    const onDragStart = async () => {
        const file = await ImageHelpers.getImageFile(canvasImageSrc);
        setDraggedImage(file);
    };
    const onDragEnd = () => {
        setDraggedImage(null);
    };

    const openMetadataDialog = (event) => {
        if (event.detail === 2) {
            if (
                (activeTool === CurateTools.StyleDrive && activeImageLayerIdSD) ||
                (activeTool === CurateTools.MixImages && activeImageLayerIdMix) ||
                (activeTool === CurateTools.SceneShift && activeImageLayerIdSceneShift)
            ) {
                const toolActiveImageLayerId = getCreationToolActiveLayerId();
                const activeImageLayerIndex = layers.findIndex((el) => el.id === toolActiveImageLayerId);
                selectLayerByOrderIndex(layers[activeImageLayerIndex].orderIndex);
            }
            toggleMetadataDialog();
        }
    };

    const getCreationToolActiveLayerId = () => {
        switch (activeTool) {
            case CurateTools.StyleDrive:
                return activeImageLayerIdSD;
            case CurateTools.MixImages:
                return activeImageLayerIdMix;
            case CurateTools.SceneShift:
                return activeImageLayerIdSceneShift;
        }
    };

    const setSizes = () => {
        let width;
        let height;
        const containerHeight = wrapperRef.current.clientHeight;
        const containerWidth = wrapperRef.current.clientWidth;
        const imageHeight = imageRef.current?.naturalHeight;
        const imageWidth = imageRef.current?.naturalWidth;

        // TODO: Usage of the setActiveImageDimensions should be removed when the metadata is fixed
        setActiveImageDimensions({ width: imageWidth, height: imageHeight });
        if (canvasImageSrc) {
            if (!imageHeight || !imageWidth) {
                return;
            }

            if (imageHeight / containerHeight < imageWidth / containerWidth) {
                width = containerWidth;
                height = (containerWidth / imageWidth) * imageHeight;
            } else {
                height = containerHeight;
                width = (containerHeight / imageHeight) * imageWidth;
            }
        } else {
            width = wrapperRef.current.clientWidth;
            height = wrapperRef.current.clientHeight;
        }

        setWidth(width);
        setHeight(height);
    };

    const updatePointerPosition = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = (event.clientX - rect.left - transform.positionX) / transform.scale;
        const y = (event.clientY - rect.top - transform.positionY) / transform.scale;

        setPosition({ x, y });
    };

    useEffect(() => {
        if (!canvasImageSrc && !modalState) {
            toggleTool();
        }
    }, [modalState]);

    useEffect(() => {
        // TODO: temporary solution, should be removed when the metadata is fixed
        if (imageRef.current) {
            const imageHeight = imageRef.current?.naturalHeight;
            const imageWidth = imageRef.current?.naturalWidth;

            setActiveImageDimensions({ width: imageWidth, height: imageHeight });
        }

        if (wrapperRef.current && imageRef.current) {
            observer.observe(imageRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [imageRef.current, wrapperRef.current]);

    useEffect(() => {
        if (zoomWrapperRef.current) {
            observer.observe(imageRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [zoomWrapperRef.current]);

    return (
        <CanvasWrapper ref={wrapperRef}>
            {(canvasImageSrc || activeImageUrlSD || activeImageUrlMix || activeImageUrlSceneShift) &&
                activeTool !== CurateTools.Lasso &&
                activeTool !== CurateTools.Eraser &&
                activeTool !== CurateTools.Brush && (
                    <ImageBox
                        ref={zoomWrapperRef}
                        width={width}
                        onClick={openMetadataDialog}
                    >
                        <TransformWrapper
                            disabled={isMetadataDialogActive}
                            wheel={{ step: CommonConstants.DEFAULT_ZOOM_STEP }}
                            disablePadding
                            limitToBounds
                            centerZoomedOut
                        >
                            <TransformComponent>
                                {canvasImageSketchSrc && activeTool !== CurateTools.StyleDrive && activeTool !== CurateTools.MixImages ? (
                                    <BackgroundImageWithOverlay
                                        crossOrigin="anonymous"
                                        ref={imageRef}
                                        onLoad={setSizes}
                                        src={canvasImageSketchSrc}
                                        path={canvasImageSrc}
                                    />
                                ) : (
                                    <BackgroundImage
                                        crossOrigin="anonymous"
                                        onClick={openMetadataDialog}
                                        onLoad={setSizes}
                                        ref={imageRef}
                                        draggable={true}
                                        onDragStart={onDragStart}
                                        onDragEnd={onDragEnd}
                                        src={
                                            (activeTool === CurateTools.StyleDrive && activeImageUrlSD) ||
                                            (activeTool === CurateTools.MixImages && activeImageUrlMix) ||
                                            (activeTool === CurateTools.SceneShift && activeImageUrlSceneShift) ||
                                            canvasImageSrc
                                        }
                                    />
                                )}
                            </TransformComponent>
                        </TransformWrapper>
                    </ImageBox>
                )}

            {(activeTool === CurateTools.Lasso || activeTool === CurateTools.Eraser || activeTool === CurateTools.Brush) && (
                <MetadataImageBox
                    ref={zoomWrapperRef}
                    width={width}
                    onClick={openMetadataDialog}
                    onMouseMove={updatePointerPosition}
                >
                    <TransformWrapper
                        disabled={isMetadataDialogActive}
                        panning={{ disabled: true }}
                        wheel={{ step: CommonConstants.DEFAULT_ZOOM_STEP }}
                        centerZoomedOut
                        limitToBounds
                        onZoom={(e) =>
                            setTransform({
                                scale: e.state.scale,
                                positionX: e.state.positionX,
                                positionY: e.state.positionY,
                            })
                        }
                    >
                        <TransformComponent>
                            {canvasImageSrc &&
                                (((activeTool === CurateTools.Lasso || activeTool === CurateTools.Eraser) && !canvasImageSketchSrc) ||
                                    activeTool === CurateTools.Brush) && (
                                    <BackgroundImage
                                        crossOrigin="anonymous"
                                        onClick={openMetadataDialog}
                                        onLoad={setSizes}
                                        ref={imageRef}
                                        draggable={false}
                                        onDragStart={onDragStart}
                                        onDragEnd={onDragEnd}
                                        src={activeTool === CurateTools.Brush ? backgroundImage.src : canvasImageSrc}
                                    />
                                )}
                            {canvasImageSrc &&
                                canvasImageSketchSrc &&
                                (activeTool === CurateTools.Lasso || activeTool === CurateTools.Eraser) && (
                                    <BackgroundImageWithOverlay
                                        crossOrigin="anonymous"
                                        onClick={openMetadataDialog}
                                        onLoad={setSizes}
                                        ref={imageRef}
                                        draggable={false}
                                        onDragStart={onDragStart}
                                        onDragEnd={onDragEnd}
                                        src={canvasImageSketchSrc}
                                        path={canvasImageSrc}
                                    />
                                )}

                            {activeTool === CurateTools.Brush && (
                                <BrushTool
                                    width={width}
                                    height={height}
                                    setModalState={setModalState}
                                    backgroundImage={backgroundImage}
                                    setBackgroundImage={setBackgroundImage}
                                    backgroundSketch={backgroundSketch}
                                    setBackgroundSketch={setBackgroundSketch}
                                    pointerPosition={{ x: position.x, y: position.y }}
                                />
                            )}
                            {activeTool === CurateTools.Lasso && (
                                <LassoTool
                                    width={width}
                                    height={height}
                                    imageRef={imageRef}
                                />
                            )}

                            {activeTool === CurateTools.Eraser && (
                                <EraserTool
                                    imageRef={imageRef}
                                    width={width}
                                    height={height}
                                    pointerPosition={{ x: position.x, y: position.y }}
                                />
                            )}
                        </TransformComponent>
                    </TransformWrapper>
                </MetadataImageBox>
            )}

            <BorderRadiusFilter />

            <CreateNewSketchModal
                open={modalState}
                close={() => setModalState(false)}
            />
            {activeTool === CurateTools.Brush && (
                <BrushDrawingSettingsWrapper>
                    <BrushDrawingSettings />
                </BrushDrawingSettingsWrapper>
            )}

            {activeTool === CurateTools.Eraser && (
                <EraserToolSettingsWrapper>
                    <EraserSettingsPanel
                        withSpaceTooltip={false}
                        eraserSize={eraserToolSize}
                        setEraserSize={setEraserToolSize}
                    />
                </EraserToolSettingsWrapper>
            )}
        </CanvasWrapper>
    );
};

export default CuratedCanvas;

const CanvasWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '&:hover > button': {
        opacity: 1,
        background: theme.palette.primary.light,
    },

    userSelect: 'none',
}));

const BrushDrawingSettingsWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',

    // 169px - half of the brush settings toolbar width
    right: 'calc(50% - 169px)',
    top: 'calc(100% + 25px)',

    '& svg': {
        fontSize: '17px',
    },
}));

const EraserToolSettingsWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    // 108px - half of the eraser settings toolbar width
    right: 'calc(50% - 108px)',
    top: 'calc(100% + 35px)',

    gap: '20px',

    '& svg': {
        fontSize: '20px',
    },
}));

export const MetadataImageBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    height: '100%',

    '& .react-transform-wrapper': {
        width: '100%',
        height: '100%',
        borderRadius: '15px',
    },

    '& .react-transform-component': {
        width: '100%',
        height: '100%',
        borderRadius: '15px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const ImageBox = styled(Box)(({ width, height }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',
    width: width ? `${width}px` : '100%',
    height: height ? `${height}px` : '100%',

    '& .react-transform-wrapper': {
        width: '100%',
        height: '100%',
        borderRadius: '15px',
    },

    '& .react-transform-component': {
        width: '100%',
        height: '100%',
        borderRadius: '15px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const BackgroundImage = styled('img')(() => ({
    height: '100%',
    width: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
    filter: 'url("#BorderRadiusFilter")',
}));

const BackgroundImageWithOverlay = styled('img')(({ path }) => ({
    height: '100%',
    width: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
    filter: 'url("#BorderRadiusFilter")',
    position: 'absolute',
    background: `no-repeat url(${path})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
}));
