import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import DrawCanvas from '@root/lib/DrawCanvas/DrawCanvas';
import { useTransformContext } from 'react-zoom-pan-pinch';
import { useCurate } from '@hooks/curate/useCurate';
import MousePointer from '../MousePointer';
import { CuratePageConstants } from '@root/utils/constants';
import { CurateTools } from '@root/utils/constants/enums';
import { AuxiliaryCanvas } from '../CuratedCanvasComponents';
import { BrushToolProps } from './BrushTool.types';

const BrushTool = ({
    width,
    height,
    setModalState,
    backgroundSketch,
    setBackgroundSketch,
    backgroundImage,
    setBackgroundImage,
    pointerPosition,
}: BrushToolProps): React.JSX.Element => {
    const {
        activeTool,
        isRedoUsed,
        isUndoUsed,
        setRedoUsed,
        setUndoUsed,
        setRedoDisable,
        setUndoDisable,
        canvasImageSrc,
        updateLayerImagePath,
        lastVisibleLayerId,
        color,
        brushSize,
        eraserSize,
        isEraserSelected,
        canvasImageSketchSrc,
        selectRequired,
    } = useCurate();

    const { transformState } = useTransformContext();

    const canvasMask = useRef<HTMLCanvasElement>(null);
    const sketchMask = useRef<HTMLCanvasElement>(null);

    const [canvasBrush, setCanvasBrush] = useState<any>(null);
    const [canvasSketch, setCanvasSketch] = useState<any>(null);

    const [historyPointsSketch, setHistoryPointsSketch] = useState<any[][]>([]);
    const [actualPointsSketch, setActualPointsSketch] = useState<any[][]>([]);

    const [isDrawing, setIsDrawing] = useState(false);
    const [updatedLayerId, setUpdatedLayerId] = useState('');

    const onMouseDown = (event?: React.PointerEvent<HTMLCanvasElement>): void => {
        if (!canvasBrush || activeTool !== CurateTools.Brush) {
            return;
        }
        setIsDrawing(true);
        setHistoryPointsSketch([...actualPointsSketch]);

        canvasBrush.onMouseDown(event, transformState.scale);
        document.addEventListener('pointerup', onMouseUpDraw, { once: true });
    };

    const onMouseMove = (event: React.PointerEvent<HTMLCanvasElement>): void => {
        if (!canvasBrush || activeTool !== CurateTools.Brush) {
            return;
        }

        canvasBrush.onMouseMove(event, transformState.scale);
    };

    const onMouseUpDraw = (): void => {
        if (!canvasBrush || activeTool !== CurateTools.Brush) {
            return;
        }
        setIsDrawing(false);

        canvasBrush.onMouseUp();
        canvasBrush.points.length && setHistoryPointsSketch((prev: any) => [...prev, [...canvasBrush.points]]);
    };

    const reselectAreas = (): void => {
        if (!canvasBrush || activeTool !== CurateTools.Brush) {
            return;
        }

        canvasBrush.clearCanvas();

        actualPointsSketch.forEach((element) => {
            canvasBrush.points = element;
            canvasBrush.renderSelection();
        });
    };

    const loadImage = (img: HTMLImageElement) =>
        new Promise((resolve, reject) => {
            img.crossOrigin = 'anonymous';
            img.onload = () => resolve(img);
            img.onerror = reject;
        });

    const createSketch = async (background: HTMLImageElement, sketchImage: HTMLImageElement): Promise<void> => {
        if (!canvasMask?.current) {
            return;
        }

        const offscreenCanvas = new OffscreenCanvas(
            sketchImage?.naturalWidth || background.naturalWidth,
            sketchImage?.naturalHeight || background.naturalHeight
        );

        const sketch = new DrawCanvas(offscreenCanvas, offscreenCanvas);
        sketch.color = canvasBrush.color;
        sketch.diameter = canvasBrush.diameter;

        if (sketchImage) {
            sketch.ctx.drawImage(sketchImage, 0, 0);
        }

        const xScale = offscreenCanvas.width / canvasMask?.current?.clientWidth;
        const yScale = offscreenCanvas.height / canvasMask?.current?.clientHeight;
        sketch.ctx.scale(xScale, yScale);

        actualPointsSketch.forEach((element) => {
            sketch.points = element;
            sketch.renderSelection();
        });

        offscreenCanvas.convertToBlob().then(async (blob) => {
            await updateLayerImagePath(blob);
        });
    };

    const drawBrushInfo = (brashSketchImageUrl: string, drawSketch = false): void => {
        const brushImg = new Image();
        brushImg.src = brashSketchImageUrl;

        setBackgroundSketch(brushImg);

        loadImage(brushImg).then(() => {
            canvasSketch.width = sketchMask?.current?.clientWidth;
            canvasSketch.height = sketchMask?.current?.clientHeight;
            if (drawSketch) {
                canvasSketch.ctx.drawImage(brushImg, 0, 0, canvasSketch.width, canvasSketch.height);
            }
        });
    };

    useEffect(() => {
        if (!canvasMask.current || canvasBrush) {
            return;
        }

        const brush = new DrawCanvas(canvasMask.current, canvasMask.current);
        setCanvasBrush(brush);
    }, [canvasMask.current]);

    useEffect(() => {
        if (!sketchMask.current || canvasSketch) {
            return;
        }

        const sketch = new DrawCanvas(sketchMask.current, sketchMask.current);
        setCanvasSketch(sketch);
    }, [sketchMask.current]);

    useEffect(() => {
        setActualPointsSketch([...historyPointsSketch]);
    }, [historyPointsSketch]);

    useEffect(() => {
        if (!canvasBrush || activeTool !== CurateTools.Brush) {
            return;
        }

        setRedoDisable(actualPointsSketch.length === historyPointsSketch.length);
        setUndoDisable(!actualPointsSketch.length || !actualPointsSketch[0].length);

        if (isRedoUsed || isUndoUsed) {
            reselectAreas();
            setUndoUsed(false);
            setRedoUsed(false);
        }

        if (!actualPointsSketch.length || !actualPointsSketch[0].length) {
            canvasBrush.clearCanvas(width, height);
        }

        setUpdatedLayerId(lastVisibleLayerId);
    }, [actualPointsSketch]);

    useEffect(() => {
        if (!canvasBrush || activeTool !== CurateTools.Brush || !historyPointsSketch.length) {
            return;
        }

        if (selectRequired && updatedLayerId !== lastVisibleLayerId) {
            createSketch(backgroundImage, backgroundSketch);
        }
    }, [selectRequired]);

    useEffect(() => {
        if (selectRequired || updatedLayerId !== lastVisibleLayerId || activeTool !== CurateTools.Brush || !historyPointsSketch.length) {
            return;
        }
        if (updatedLayerId === lastVisibleLayerId) {
            createSketch(backgroundImage, backgroundSketch);
        }
    }, [actualPointsSketch]);

    useEffect(() => {
        if (!canvasBrush) {
            return;
        }

        canvasBrush.enable = activeTool === CurateTools.Brush;
        if (!canvasImageSrc && activeTool === CurateTools.Brush) {
            setModalState(true);
        }

        if (lastVisibleLayerId && activeTool === CurateTools.Brush) {
            canvasBrush.color = isEraserSelected ? CuratePageConstants.DEFAULT_ERASER_COLOR : color;

            canvasBrush.diameter = isEraserSelected ? eraserSize : brushSize;

            const img = new Image();
            img.src = canvasImageSrc;
            setBackgroundImage(img);

            loadImage(img).then(() => {
                setHistoryPointsSketch([]);
                if (canvasImageSketchSrc) {
                    drawBrushInfo(canvasImageSketchSrc, true);
                } else {
                    setBackgroundSketch(null);
                }
            });
        }
    }, [activeTool, lastVisibleLayerId, canvasBrush]);

    useEffect(() => {
        if (activeTool !== CurateTools.Brush || !canvasBrush) {
            return;
        }

        if (canvasImageSrc) {
            canvasBrush.color = isEraserSelected ? CuratePageConstants.DEFAULT_ERASER_COLOR : color;
            canvasBrush.diameter = isEraserSelected ? eraserSize : brushSize;
        }
    }, [color, brushSize, eraserSize, isEraserSelected]);

    useEffect(() => {
        if (activeTool !== CurateTools.Brush || !canvasBrush) {
            return;
        }

        canvasBrush.clearCanvas(canvasMask?.current?.clientWidth, canvasMask?.current?.clientHeight);

        if (sketchMask?.current) {
            canvasSketch.clearCanvas(sketchMask.current.clientWidth, sketchMask.current.clientHeight);
        }
    }, [lastVisibleLayerId]);

    useEffect(() => {
        if (!canvasSketch) {
            return;
        }

        if (canvasImageSketchSrc) {
            drawBrushInfo(canvasImageSketchSrc, true);
            setHistoryPointsSketch([]);
        }
    }, [height, width]);

    useEffect(() => {
        if (isUndoUsed && activeTool === CurateTools.Brush) {
            setActualPointsSketch((prev: any) => prev.slice(0, -1));
        }
    }, [isUndoUsed]);

    useEffect(() => {
        if (isRedoUsed && activeTool === CurateTools.Brush) {
            setActualPointsSketch((prev: any) => [...prev, historyPointsSketch[actualPointsSketch.length]]);
        }
    }, [isRedoUsed]);

    return (
        <>
            <SketchCanvas
                display={activeTool === CurateTools.Brush && backgroundSketch ? 1 : 0}
                width={width}
                height={height}
                ref={sketchMask}
            ></SketchCanvas>

            <AuxiliaryCanvas
                isTransparent={isEraserSelected}
                animated={false}
                display={activeTool === CurateTools.Brush ? 1 : 0}
                onPointerDown={onMouseDown}
                onPointerMove={onMouseMove}
                width={width}
                height={height}
                ref={canvasMask}
            ></AuxiliaryCanvas>

            {pointerPosition && !isDrawing && (
                <MousePointer
                    size={isEraserSelected ? eraserSize : brushSize}
                    position={pointerPosition}
                    color={isEraserSelected ? CuratePageConstants.DEFAULT_BRUSH_POINTER_COLOR : color}
                    onMouseDown={onMouseDown}
                />
            )}
        </>
    );
};

export default BrushTool;

const SketchCanvas = styled('canvas')<{ display: number }>(({ display }) => ({
    position: 'absolute',
    touchAction: 'none',
    opacity: 0.7,
    ...(!display && { display: 'none' }),
}));
