import React from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { SceneModeIcon } from '@root/assets/icons/SceneModeIcon';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { ExpandLeftIcon } from '@root/assets/icons/ExpandLeftIcon';
import { DownloadIcon } from '@root/assets/icons/DownloadIcon';
import { ScreenshotIcon } from '@root/assets/icons/ScreenshotIcon';
import { ImagePreviewBoxProps } from './ImagePreviewBox.types';

const ImagePreviewBox = ({ imageUrl, onClick, type, onDelete, onDownload }: ImagePreviewBoxProps): React.JSX.Element => {
    const handleClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        if (onClick) {
            onClick();
        }
    };

    const handleDelete = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        if (onDelete) {
            onDelete();
        }
    };

    const handleDownload = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        if (onDownload) {
            onDownload();
        }
    };

    return (
        <>
            <MinimizedBox onClick={handleClick}>
                <ActionButton
                    onClick={handleClick}
                    highlight={'primary'}
                >
                    <ExpandLeftIcon />
                </ActionButton>
                {type === 'image' && (
                    <ActionButton
                        bottom={0}
                        highlight={'primary'}
                        onClick={handleDownload}
                    >
                        <DownloadIcon />
                    </ActionButton>
                )}
                <MinimizedImage
                    crossOrigin="anonymous"
                    src={imageUrl}
                ></MinimizedImage>
                <ActionButton
                    visible
                    right={0}
                    onClick={handleClick}
                >
                    {type === 'image' ? <ScreenshotIcon /> : <SceneModeIcon />}
                </ActionButton>
                {type === 'image' && (
                    <ActionButton
                        highlight={'error'}
                        bottom={0}
                        right={0}
                        onClick={handleDelete}
                    >
                        <DeleteImageIcon />
                    </ActionButton>
                )}
            </MinimizedBox>
        </>
    );
};

export default ImagePreviewBox;

const ActionButton = styled(IconButton)<{
    right?: number;
    bottom?: number;
    visible?: boolean;
    highlight?: 'error' | 'primary' | 'default';
}>(({ theme, right = -1, bottom = -1, visible, highlight = 'default' }) => ({
    width: '32px',
    height: '32px',
    position: 'absolute',

    color: theme.palette.neutrals.inactive1,
    opacity: visible ? 1 : 0,

    ...(right >= 0 && {
        right: right,
    }),

    ...(bottom >= 0 && {
        bottom: bottom,
    }),

    '& svg': {
        width: '20px',
        height: '20px',
    },

    '&:hover': {
        background: 'transparent',
        color: highlight === 'default' ? `${theme.palette.primary.contrastText}!important` : `${theme.palette[highlight].main}!important`,
    },
}));

const MinimizedBox = styled(Box)(({ theme }) => ({
    bottom: '13px',
    right: '12px',
    position: 'absolute',

    cursor: 'pointer',
    height: '25%',
    borderRadius: '8px',

    '&:hover': {
        button: {
            opacity: '1 !important',
            color: theme.palette.primary.contrastText,
        },
    },
}));

const MinimizedImage = styled('img')(() => ({
    height: '100%',
    width: '100%',
    borderRadius: '8px',
}));
