import React, { useEffect, useRef, useState } from 'react';
import { useTransformContext } from 'react-zoom-pan-pinch';
import SelectCanvasArea from '@root/lib/SelectCanvas/SelectCanvasArea';
import { useCurate } from '@hooks/curate/useCurate';
import { CurateTools } from '@root/utils/constants/enums';
import { AuxiliaryCanvas } from '../CuratedCanvasComponents';
import { LassoToolProps } from './LassoTool.types';

const LassoTool = ({ width, height, imageRef }: LassoToolProps): React.JSX.Element => {
    const { activeTool, isRedoUsed, isUndoUsed, setRedoUsed, setUndoUsed, setRedoDisable, setUndoDisable, setInputMask, isEraserSelected } =
        useCurate();
    const { transformState } = useTransformContext();
    const canvasMask = useRef<HTMLCanvasElement>(null);

    const [canvasSelector, setCanvasSelector] = useState<any>(null);
    const [isShiftDown, setShiftDown] = useState(false);
    const [historyPointsLasso, setHistoryPointsLasso] = useState<any[][]>([]);
    const [actualPointsLasso, setActualPointsLasso] = useState<any[][]>([]);

    const onMouseDown = (event: React.PointerEvent<HTMLCanvasElement>): void => {
        if (!canvasSelector || activeTool !== CurateTools.Lasso) {
            return;
        }

        if (isShiftDown) {
            setHistoryPointsLasso([...actualPointsLasso]);
        } else {
            canvasSelector.clearCanvas();
            setHistoryPointsLasso([]);
        }

        canvasSelector.onMouseDown(event, transformState.scale);
        document.addEventListener('pointerup', onMouseUpLasso, { once: true });
    };

    const onMouseMove = (event: React.PointerEvent<HTMLCanvasElement>): void => {
        if (!canvasSelector || activeTool !== CurateTools.Lasso) {
            return;
        }

        canvasSelector.onMouseMove(event, transformState.scale);
    };

    const onMouseUpLasso = (): void => {
        if (!canvasSelector || activeTool !== CurateTools.Lasso) {
            return;
        }
        canvasSelector.onMouseUp();
        canvasSelector.points.length && setHistoryPointsLasso((prev: any) => [...prev, [...canvasSelector.points]]);
    };

    const reselectAreas = (): void => {
        if (!canvasSelector || activeTool !== CurateTools.Lasso) {
            return;
        }

        canvasSelector.clearCanvas();

        actualPointsLasso.forEach((element: any) => {
            canvasSelector.points = element;
            canvasSelector.renderSelection();
            canvasSelector.endPath();
        });
    };

    const createMask = (): void => {
        if (!canvasMask?.current) {
            return;
        }

        const canvas = document.createElement('canvas');

        canvas.width = canvasMask.current.clientWidth;
        canvas.height = canvasMask.current.clientHeight;

        const mask = new SelectCanvasArea(canvas, canvas);
        mask.clearCanvas(canvas.width, canvas.height);
        mask.ctx.fillStyle = 'black';
        mask.ctx.fillRect(0, 0, canvas.width, canvas.height);

        actualPointsLasso.forEach((element) => {
            mask.points = element;
            mask.fillStyle = 'white';
            mask.isLineDash = false;
            mask.lineWidth = 1;
            mask.renderSelection();
            mask.endPath();
        });

        const image = new Image();
        image.src = canvas.toDataURL('image/png');
        image.addEventListener('load', () => {
            canvas.width = imageRef.current.naturalWidth;
            canvas.height = imageRef.current.naturalHeight;
            mask.clearCanvas(canvas.width, canvas.height);
            mask.ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            setInputMask(canvas.toDataURL('image/png'));
        });
    };

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Shift') {
                setShiftDown(true);
            }
        });
        window.addEventListener('keyup', (e) => {
            if (e.key === 'Shift') {
                setShiftDown(false);
            }
        });

        return () => {
            window.removeEventListener('keydown', (e) => {
                if (e.key === 'Shift') {
                    setShiftDown(true);
                }
            });
            window.removeEventListener('keyup', (e) => {
                if (e.key === 'Shift') {
                    setShiftDown(false);
                }
            });
        };
    }, []);

    useEffect(() => {
        if (!canvasMask.current || canvasSelector) {
            return;
        }
        const selector = new SelectCanvasArea(canvasMask.current, canvasMask.current);
        setCanvasSelector(selector);
    }, [canvasMask.current]);

    useEffect(() => {
        setActualPointsLasso([...historyPointsLasso]);
    }, [historyPointsLasso]);

    useEffect(() => {
        if (!canvasSelector || activeTool !== CurateTools.Lasso) {
            return;
        }

        setRedoDisable(actualPointsLasso.length === historyPointsLasso.length);
        setUndoDisable(!actualPointsLasso.length);

        if (isRedoUsed || isUndoUsed) {
            reselectAreas();
            setUndoUsed(false);
            setRedoUsed(false);
        }

        actualPointsLasso.length ? imageRef.current && createMask() : setInputMask('');
    }, [actualPointsLasso]);

    useEffect(() => {
        if (!canvasSelector || activeTool !== CurateTools.Lasso) {
            return;
        }

        canvasSelector.enable = activeTool === CurateTools.Lasso;
        reselectAreas();

        setRedoDisable(actualPointsLasso.length === historyPointsLasso.length);
        setUndoDisable(!actualPointsLasso.length);
    }, [activeTool, canvasSelector]);

    useEffect(() => {
        if (isUndoUsed && activeTool === CurateTools.Lasso) {
            setActualPointsLasso((prev: any) => prev.slice(0, -1));
        }
    }, [isUndoUsed]);

    useEffect(() => {
        if (isRedoUsed && activeTool === CurateTools.Lasso) {
            setActualPointsLasso((prev: any) => [...prev, historyPointsLasso[actualPointsLasso.length]]);
        }
    }, [isRedoUsed]);

    return (
        <AuxiliaryCanvas
            isTransparent={isEraserSelected}
            animated={false}
            display={activeTool === CurateTools.Lasso ? 1 : 0}
            onPointerDown={onMouseDown}
            onPointerMove={onMouseMove}
            width={width}
            height={height}
            ref={canvasMask}
        ></AuxiliaryCanvas>
    );
};

export default LassoTool;
