import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Box, Typography, IconButton, Modal, Stack, styled, Tooltip } from '@mui/material';
import {
    MetadataImageBox,
    MetadataInfoWrapper,
    MetadataLeftPanel,
    MetadataRightPanel,
    MetadataSectionWrapper,
    MetadataToolButton,
    MetadataTools,
} from '@root/components/ImageMetadataPanel';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import TunesContext from '@context/TunesContext/TunesContext';
import ModelsContext from '@context/ModelsContext/ModelsContext';
import { useNotifications } from '@context/NotificationsContext/useNotifications';
import { ScaleButton } from '@pages/Generations/GeneratePage/components/fullSizeImage/ScaleButton';
import { useUpscale } from '@hooks/upscale/UseUpscale';
import { useJobs } from '@hooks/jobs/UseJobs';
import { useCurate } from '@root/hooks/curate/useCurate';
import ProjectsContext from '@context/ProjectsContext/ProjectsContext';
import { MoveToCurateIcon } from '@root/assets/icons/MoveToCurateIcon';
import { DownloadIcon } from '@root/assets/icons/DownloadIcon';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import BorderRadiusFilter from '@root/assets/filters';
import { NavigationPaths, UpscaleStatus } from '@root/utils/constants/enums';
import CopyLinkButton from '@root/components/CopyValueButton';
import InfoBlock from '@root/components/InfoBlock';
import BackButton from '@root/components/BackButton';
import ForwardButton from '@root/components/ForwardButton';
import ImageMetadataRow from '@root/components/ImageMetadataRow';
import ImageInfluenceInfo from '@root/components/ImageInfluenceInfo';
import { FullScreenOpenIcon } from '@root/assets/icons/FullScreenOpenIcon';
import { ImageHelpers } from '@root/utils/helpers';
import { CommonConstants } from '@root/utils/constants';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

const CHECK_IMAGES_STATUS_INTERVAL_TIME = 3000;

const FullSizeImage = ({
    onClose,
    configuration,
    activeImage,
    likeImage,
    onDelete,
    controlDepthImage,
    controlSketchImage,
    toggleFullSizeMode,
    onPrevious,
    onNext,
}) => {
    const { handleSetTunes, setActiveImageSrc } = useContext(TunesContext);
    const { activeProject } = useContext(ProjectsContext);
    const { updateJobStatus } = useJobs();
    const { getModelNameByConfiguration } = useContext(ModelsContext);
    const { createLayerFromCreate } = useCurate();
    const { createNotification, notificationMessages } = useNotifications();
    const { upscaleImage: upscaleImagePost, getUpscaledImageById } = useUpscale();
    const navigate = useNavigate();
    const { checkLicenseStatus } = useLicenseValidation();

    const [loaderState, setLoaderState] = useState(true);

    const [image, setImage] = useState();
    const [src, setSrc] = useState('');
    const [displayedImage, setDisplayedImage] = useState(activeImage);

    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [isUpscaleEnabled, setIsUpscaleEnabled] = useState(false);

    const [upscaleOptions, setUpscaleOptions] = useState([]);

    const hidePageControls = !onPrevious && !onNext;

    const saveImage = async () => {
        ImageHelpers.downloadPngByUrl(src);
    };

    const handleLikeImage = () => {
        if (activeImage?.Id) {
            likeImage(activeImage?.Id);
        }
    };

    const handleDeleteImage = () => {
        if (activeImage?.Id) {
            onDelete(activeImage?.Id);
        }
    };

    const setDimension = (height, width, isOriginalImage = true) => {
        setHeight(height);
        setWidth(width);

        if (isOriginalImage) {
            setIsUpscaleEnabled(height * width <= CommonConstants.IMAGE_MAX_SIZE_FOR_UPSCALE);
        }
    };

    const handleMenuClick = (optionIndex) => {
        if (!upscaleOptions[optionIndex].status || upscaleOptions[optionIndex].status === UpscaleStatus.Error) {
            upscaleImage(optionIndex);
        } else {
            showUpscaledImage(optionIndex);
        }
    };

    const upscaleImage = async (optionIndex) => {
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        const data = {
            imageId: image?.Id,
            upscaleFactor: upscaleOptions[optionIndex].value,
        };

        const result = await upscaleImagePost(data);
        updateJobStatus();
        if (result && !result.errorCode) {
            handleSetTunes(activeProject.Id);
            setUpscaleOptions((prev) => {
                prev[optionIndex].status = UpscaleStatus.Processing;
                return prev;
            });
        }

        getUpscaledImages();
    };

    const showUpscaledImage = (optionIndex) => {
        setUpscaleOptions((prev) => {
            if (prev[optionIndex].state) {
                return prev;
            }

            prev[optionIndex].state = true;

            const otherOptions = prev.filter((el, i) => i !== optionIndex && el.state);

            otherOptions.forEach((el) => (el.state = false));

            setSrc(prev[optionIndex].path);
            setDisplayedImage(prev[optionIndex]);
            setActiveImageSrc(prev[optionIndex].path);

            showLoader();

            const height = configuration.height * (prev[optionIndex].value === 'original' ? 1 : prev[optionIndex].value);

            const width = configuration.width * (prev[optionIndex].value === 'original' ? 1 : prev[optionIndex].value);

            setDimension(height, width, prev[optionIndex].value === 'original');

            return prev;
        });
    };

    const getUpscaledImages = async (predefinedImage) => {
        const selectedImage = predefinedImage ? predefinedImage : image;
        const imageVersions = await getUpscaledImageById(selectedImage?.Id);
        if (imageVersions?.errorCode || !imageVersions?.length) {
            return;
        }

        setUpscaleOptions((prev) => {
            const newOptions = prev.map((option) => {
                const upscaleImage = findUpscaleImageWithScaleValue(imageVersions, option.value);

                if (option.value === 'original' || !upscaleImage) {
                    return option;
                }

                if (selectedImage?.Id === activeImage?.Id) {
                    if (option.status === UpscaleStatus.Processing && upscaleImage.StatusId === UpscaleStatus.Ready) {
                        createNotification(notificationMessages.image.upscale.title, notificationMessages.image.upscale.success, 'browser');
                        handleSetTunes(activeProject.Id);
                    }

                    option.id = upscaleImage.FileId;
                    option.status = upscaleImage.StatusId;

                    if (upscaleImage.image) {
                        option.path = upscaleImage?.image?.imageUrl;
                    }
                }

                return option;
            });

            return newOptions;
        });
    };

    const findUpscaleImageWithScaleValue = (upscaleImages, scaleValue) => {
        const images = upscaleImages.filter((image) => image.Configurations.includes(scaleValue));
        images.sort((a, b) => a.StatusId - b.StatusId);
        return images[0] ?? null;
    };

    const showLoader = () => {
        setLoaderState(true);
    };

    const hideLoader = () => {
        setLoaderState(false);
    };

    const handleUserKeyPress = (event) => {
        const { keyCode } = event;
        if (keyCode === 39 && onNext) {
            onNext();
        } else if (keyCode === 37 && onPrevious) {
            onPrevious();
        }
    };

    const handleClose = () => {
        onClose();
        setSrc('');
        setImage('');
    };

    const moveWithImageToCurate = async () => {
        await createLayerFromCreate(activeImage?.Id);
        navigate(`/${NavigationPaths.RefinePage}`);
    };

    const generateDefaultScaleOptions = () => {
        const options = [
            {
                label: 'Original',
                value: 'original',
                state: true,
                status: UpscaleStatus.Ready,
                path: activeImage?.imageUrl,
            },
            {
                label: '2x',
                value: 2,
                state: false,
                status: '',
                id: '',
                path: '',
            },
            {
                label: '4x',
                value: 4,
                state: false,
                status: '',
                id: '',
                path: '',
            },
        ];
        options.push({
            label: '8x',
            value: 8,
            state: false,
            status: '',
            id: '',
            path: '',
        });
        return options;
    };

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);
        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    useEffect(() => {
        if (!activeImage) {
            setSrc('');
            setDisplayedImage('');
            setHeight('');
            setWidth('');
            setIsUpscaleEnabled(false);
            return;
        }

        let options = generateDefaultScaleOptions();

        const enhancementsConfig = activeImage.ImageEnhancements;
        if (enhancementsConfig?.length) {
            options = options.map((option) => {
                const config = findUpscaleImageWithScaleValue(enhancementsConfig, option.value);
                return {
                    ...option,
                    status: config?.StatusId ?? option.status,
                };
            });
        }

        setUpscaleOptions(options);

        getUpscaledImages(activeImage);

        setSrc(activeImage.imageUrl);
        setImage(activeImage);

        setDisplayedImage(activeImage);

        if (configuration) {
            setDimension(configuration.height, configuration.width);
        }
    }, [activeImage]);

    useEffect(() => {
        const isProcessing = upscaleOptions.find((el) => el.status === UpscaleStatus.Enqueued || el.status === UpscaleStatus.Processing);

        const timer = setInterval(() => {
            isProcessing && getUpscaledImages();
        }, CHECK_IMAGES_STATUS_INTERVAL_TIME);

        return () => clearTimeout(timer);
    }, [upscaleOptions]);

    return (
        <SectionModal
            open={true}
            onClose={handleClose}
        >
            <MetadataSectionWrapper>
                <BorderRadiusFilter />

                {!hidePageControls && (
                    <>
                        <BackButton
                            moveToPrevious={onPrevious}
                            isDisabled={!onPrevious}
                        />
                        <ForwardButton
                            moveToNext={onNext}
                            isDisabled={!onNext}
                        />
                    </>
                )}

                <MetadataLeftPanel>
                    <Toolbar>
                        <ToolbarIcon
                            disableRipple
                            onClick={toggleFullSizeMode}
                        >
                            <FullScreenOpenIcon />
                            <ToolbarLabel>{'Zoom'}</ToolbarLabel>
                        </ToolbarIcon>
                    </Toolbar>
                    <MetadataImageBox>
                        <TransformWrapper
                            wheel={{ step: CommonConstants.DEFAULT_ZOOM_STEP }}
                            centerZoomedOut
                            disablePadding
                            limitToBounds
                        >
                            <TransformComponent>
                                <img
                                    crossOrigin="anonymous"
                                    loading="lazy"
                                    onLoad={hideLoader}
                                    src={src}
                                />
                            </TransformComponent>
                        </TransformWrapper>
                    </MetadataImageBox>
                    {loaderState && (
                        <LoaderWrapper>
                            <span className="loader"></span>
                        </LoaderWrapper>
                    )}
                </MetadataLeftPanel>
                <MetadataRightPanel>
                    <MetadataInfoWrapper>
                        <MetadataTools>
                            <Tooltip title="Send to Refine">
                                <span>
                                    <MetadataToolButton onClick={moveWithImageToCurate}>
                                        <MoveToCurateIcon />
                                    </MetadataToolButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Download image">
                                <span>
                                    <MetadataToolButton onClick={saveImage}>
                                        <DownloadIcon />
                                    </MetadataToolButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Copy link">
                                <span>
                                    <MetadataToolButton>
                                        <CopyLinkButton value={src} />
                                    </MetadataToolButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Delete image">
                                <span>
                                    <MetadataToolButton onClick={handleDeleteImage}>
                                        <DeleteImageIcon />
                                    </MetadataToolButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Like  image">
                                <span>
                                    <MetadataToolButton onClick={handleLikeImage}>
                                        {activeImage?.Liked ? <Favorite /> : <FavoriteBorder />}
                                    </MetadataToolButton>
                                </span>
                            </Tooltip>
                        </MetadataTools>
                        <Upscale>
                            <InfoLabel variant="body16">{'upscale mode'}</InfoLabel>
                            <Stack
                                direction="row"
                                justifyContent="start"
                            >
                                {upscaleOptions.map((opt, index) => (
                                    <ScaleButton
                                        disabled={!isUpscaleEnabled}
                                        onClick={() => handleMenuClick(index)}
                                        upscaleOption={opt}
                                        key={index}
                                    />
                                ))}
                            </Stack>
                        </Upscale>
                        <ImageMetadataRow
                            title={'prompt'}
                            info={configuration?.prompt}
                        />
                        <ImageMetadataRow
                            title={'model'}
                            info={getModelNameByConfiguration(configuration)}
                        />
                        <ImageMetadataRow
                            title={'seed'}
                            info={configuration?.seed}
                        />
                        <ImageMetadataRow
                            title={'size'}
                            info={`${width} x ${height}`}
                        />
                        <Stack direction={'column'}>
                            {controlDepthImage?.path && (
                                <InfoItem>
                                    <InfoBlock
                                        fullWidth
                                        title={'control image composition'}
                                        infoImage={controlDepthImage.path}
                                    />
                                    <ImageInfluenceInfo
                                        title={'influence'}
                                        value={`${controlDepthImage.influence * 100}%`}
                                    />
                                </InfoItem>
                            )}
                            {controlSketchImage?.path && (
                                <InfoItem>
                                    <InfoBlock
                                        fullWidth
                                        title={'sketches'}
                                        infoImage={controlSketchImage.path}
                                    />
                                    <ImageInfluenceInfo
                                        title={'influence'}
                                        value={`${controlSketchImage.influence * 100}%`}
                                    />
                                </InfoItem>
                            )}
                        </Stack>
                    </MetadataInfoWrapper>
                </MetadataRightPanel>
            </MetadataSectionWrapper>
        </SectionModal>
    );
};

export default FullSizeImage;

const SectionModal = styled(Modal)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none !important',
    background: '#00000085',
}));

const LoaderWrapper = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    height: '100%',

    position: 'absolute',
    top: '0',
    left: '0',

    zIndex: '100',
    justifyContent: 'center',
    alignItems: 'center',
}));

const InfoItem = styled(Box)(() => ({
    display: 'flex',
    gap: '14px',
    flexDirection: 'column',
    width: 'fit-content',
}));

const Upscale = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'column',
    gap: 5,
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    color: theme.palette.primary.inactive,
}));

const ToolbarLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    color: theme.palette.primary.inactive,
    fontSize: '16px',
    padding: '0px 5px 0px 5px',
}));

const Toolbar = styled(Box)(() => ({
    display: 'flex',
    position: 'absolute',

    bottom: '5vh',

    overflow: 'hidden',
    opacity: '1',
    zIndex: 2,
}));

const ToolbarIcon = styled(IconButton)(({ theme }) => ({
    height: '38px',
    padding: '5px 0px',
    paddingLeft: '6px',
    paddingRight: '4px',

    color: theme.palette.primary.inactive,
    backgroundColor: theme.palette.neutrals.main,
    borderRadius: '12px',

    '& svg': {
        width: '28px',
        height: '28px',
    },
}));
