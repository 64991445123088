import React from 'react';
import { MousePointerProps } from './MousePointer.types';
import { Box, styled } from '@mui/system';

const MousePointer = ({ size, position, color, onMouseDown, onMouseUp }: MousePointerProps): React.JSX.Element => {
    return (
        <Wrapper
            size={size}
            pos={position}
            color={color}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        />
    );
};

export default MousePointer;

const Wrapper = styled(Box)<{
    size: number;
    pos: { x: number; y: number } | null;
    color: string;
}>(({ size, pos, color }) => ({
    position: 'absolute',
    width: size * 2,
    height: size * 2,
    borderRadius: '50%',
    border: `1px solid ${color}`,

    ...(pos?.x && {
        left: pos.x,
    }),
    ...(pos?.y && {
        top: pos.y,
    }),
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
}));
