import * as THREE from 'three';
import { degToRad, radToDeg } from 'three/src/math/MathUtils';
import { DepixCamera } from '@lib/DepixCamera';
import { toFloat } from '@root/utils/toFloat';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { TransformControls } from 'three/addons/controls/TransformControls.js';
import { Model3dErrorCode, RotationAxis, SupportedModelTypes, SupportedModelTypesInfo } from '@root/utils/constants/enums';
import { CameraViewConstants, CommonConstants } from '@root/utils/constants';

class Scene {
    constructor(canvasElement, viewportWidth, viewportHeight) {
        this.canvasElement = canvasElement;

        this.viewportDimensions = {
            width: viewportWidth,
            height: viewportHeight,
        };

        this.scene = this.initScene();
        this.camera = this.initCamera();
        this.applyLight();

        this.renderer = this.initRenderer(this.canvasElement, this.viewportDimensions);

        this.transformControls = this.initControls();

        this.targetAngleX = degToRad(10);
        this.targetAngleY = 0;
        this.targetAngleZ = 0;
        this.targetModelPosition = { x: 0, y: 0, z: 0 };
    }

    initScene() {
        const scene = new THREE.Scene();

        return scene;
    }

    initRenderer(canvasElement, viewportDimensions) {
        const { width, height } = viewportDimensions;

        const renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            canvas: canvasElement,
        });

        renderer.setSize(width, height);

        return renderer;
    }

    initCamera() {
        const { width, height } = this.viewportDimensions;

        const fieldOfView = 45;
        const aspectRatio = width / height;
        const nearClip = 0.1;
        const farClip = 1000;

        const camera = new DepixCamera(fieldOfView, aspectRatio, nearClip, farClip);

        camera.position.set(0, 50, 550);

        this.scene.add(camera);

        return camera;
    }

    initControls() {
        const controls = new TransformControls(this.camera, this.renderer.domElement);
        return controls;
    }

    clearScene() {
        for (var i = this.scene.children.length - 1; i >= 0; i--) {
            const obj = this.scene.children[i];
            this.scene.remove(obj);
        }

        this.update();
    }

    getLoader(objType) {
        switch (objType) {
            case SupportedModelTypesInfo[SupportedModelTypes.OBJ].name:
                return new OBJLoader();
            case SupportedModelTypesInfo[SupportedModelTypes.FBX].name:
                return new FBXLoader();
            case SupportedModelTypesInfo[SupportedModelTypes.GLTF].name:
            case SupportedModelTypesInfo[SupportedModelTypes.GLB].name:
                var loader = new GLTFLoader();
                var dracoLoader = new DRACOLoader();
                dracoLoader.setDecoderPath(CommonConstants.DRACO_DECODER_PATH);
                loader.setDRACOLoader(dracoLoader);
                return loader;
            case SupportedModelTypesInfo[SupportedModelTypes.STL].name:
                return new STLLoader();
        }
    }

    applyLight() {
        const ambientLight = new THREE.AmbientLight(CameraViewConstants.DEFAULT_LIGHT_COLOR, 0.2);
        this.scene.add(ambientLight);

        const topLight = new THREE.DirectionalLight(CameraViewConstants.DEFAULT_LIGHT_COLOR, 0.5);
        topLight.position.set(100, 200, 200);
        this.scene.add(topLight);

        const sideLight = new THREE.DirectionalLight(CameraViewConstants.DEFAULT_LIGHT_COLOR, 0.5);
        sideLight.position.set(-200, 0, 0);
        this.scene.add(sideLight);
    }

    applyMaterial(model, objType) {
        const defaultMaterial = new THREE.MeshPhysicalMaterial(CameraViewConstants.DEFAULT_MATERIAL_SETTING);

        if (objType === SupportedModelTypesInfo[SupportedModelTypes.STL].name) {
            return;
        } else {
            if (model?.children[0]?.material) {
                model.children[0].material = defaultMaterial;
            }
            model.traverse(function (child) {
                if (child?.isMesh) {
                    child.material = defaultMaterial;
                    if (!child?.geometry?.attributes?.normal) {
                        child?.geometry?.computeVertexNormals();
                    }
                }
            });
        }
    }

    setTransformMode(mode) {
        if (!this.transformControls) {
            return;
        }

        switch (mode) {
            case 'translate':
                this.transformControls.setMode('translate');
                this.update();
                break;
            case 'rotate':
                this.transformControls.setMode('rotate');
                this.update();
                break;
        }
    }

    handleLoadError(url, setError, setIsLoading) {
        console.error(url);
        setError(Model3dErrorCode.Upload);
        setIsLoading(false);
    }

    async addModel(modelPath, objType, startRotation, startPosition, isDefault, setIsLoading, setCustomInput, setError) {
        let modelLoader;

        const type = objType.toLowerCase();
        if (this.transformControls) {
            this.transformControls.detach();
            this.transformControls.removeEventListener('change', () => {
                this.renderCameraViewport();
            });

            this.transformControls.removeEventListener('objectChange', () => {
                setCustomInput(true);
            });
        }

        var selectedObject = this.scene.getObjectByName(CameraViewConstants.UPLOADED_MODEL_NAME);
        this.scene.remove(selectedObject);
        this.update();

        if (isDefault) {
            modelLoader = new THREE.ObjectLoader();
        } else {
            modelLoader = this.getLoader(type);
        }

        const bodyMaterial = new THREE.MeshPhysicalMaterial(CameraViewConstants.DEFAULT_MATERIAL_SETTING);

        modelLoader.load(
            modelPath,
            (model) => {
                try {
                    const xRot = degToRad(startRotation.x);
                    const yRot = degToRad(startRotation.y);
                    const zRot = degToRad(startRotation.z);

                    if (
                        type === SupportedModelTypesInfo[SupportedModelTypes.GLTF].name ||
                        type === SupportedModelTypesInfo[SupportedModelTypes.GLB].name
                    ) {
                        model = model.scene;
                    }

                    if (type === SupportedModelTypesInfo[SupportedModelTypes.STL].name) {
                        model = new THREE.Mesh(model, bodyMaterial);
                    }

                    this.applyMaterial(model, type);

                    model.position.set(startPosition.x, startPosition.y, startPosition.z);
                    model.rotation.set(xRot, yRot, zRot);

                    model.traverse(function (child) {
                        if (child instanceof THREE.Group) {
                            child.position.set(0, 0, 0);
                        } else if (child instanceof THREE.Mesh && !!child.geometry) {
                            child.position.set(0, 0, 0);
                        }
                    });

                    const box = new THREE.Box3().setFromObject(model);
                    const size = new THREE.Vector3();
                    box.getSize(size);
                    const scaleFactor = Math.min(this.viewportDimensions.height, this.viewportDimensions.width) / size.x;

                    model.scale.set(scaleFactor, scaleFactor, scaleFactor);
                    model.name = CameraViewConstants.UPLOADED_MODEL_NAME;
                    this.model = model;

                    this.targetAngleX = startRotation.x;
                    this.targetAngleY = startRotation.y;
                    this.targetAngleZ = startRotation.z;
                    this.targetModelPosition = startPosition;

                    this.scene.add(model);

                    this.transformControls.attach(model);
                    this.transformControls.addEventListener('change', () => {
                        this.renderCameraViewport();
                    });

                    this.transformControls.addEventListener('objectChange', () => {
                        setCustomInput(true);
                    });

                    this.transformControls.setSize(1.5);
                    this.scene.add(this.transformControls);

                    this.update();
                    this.saveCanvasAsImage();
                    setIsLoading(false);
                } catch (error) {
                    console.error(error);
                    setIsLoading(false);
                    setError(Model3dErrorCode.Render);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {},
            (url) => {
                this.handleLoadError(url, setError, setIsLoading);
            }
        );
    }

    renderCameraViewport() {
        this.renderer.render(this.scene, this.camera);
    }

    onWindowResize(width, height) {
        this.viewportDimensions.width = width;
        this.viewportDimensions.height = height;

        this.camera.aspect = width / height;

        this.renderer.setSize(width, height);
        this.camera.updateProjectionMatrix();
        this.update();
    }

    startModelRotation(xDeg, yDeg, zDeg, zoom, modelPosition) {
        this.stopModelRotation(this.targetAngleX, this.targetAngleY, this.targetAngleZ, this.targetModelPosition);
        if (!this.isRotating) {
            this.isRotating = true;
            const angleX = degToRad(xDeg);
            const angleY = degToRad(yDeg);
            const angleZ = degToRad(zDeg);

            this.targetAngleX = angleX;
            this.targetAngleY = angleY;
            this.targetAngleZ = angleZ;
            this.targetModelPosition = modelPosition;

            this.camera.setZoom(zoom);
            this.animateModelRotation();
        }
    }

    getCameraPosition() {
        return {
            xRotation: radToDeg(this.model.rotation.x),
            yRotation: radToDeg(this.model.rotation.y),
            zRotation: radToDeg(this.model.rotation.z),
            zoom: this.camera.zoom,
            modelPosition: {
                x: this.model.position.x,
                y: this.model.position.y,
                z: this.model.position.z,
            },
        };
    }

    stopModelRotation(prevAngleX, prevAngleY, prevAngleZ, prevPosition) {
        this.isRotating = false;
        this.model.rotation.x = prevAngleX;
        this.model.rotation.y = prevAngleY;
        this.model.rotation.z = prevAngleZ;
        this.model.position.x = prevPosition.x;
        this.model.position.y = prevPosition.y;
        this.model.position.z = prevPosition.z;
        this.saveCanvasAsImage();
    }

    animateModelRotation = () => {
        if (!this.isRotating) {
            return;
        }

        this.rotateModel();

        this.moveModelToTargetPosition();
        this.update();

        const id = requestAnimationFrame(this.animateModelRotation);
        if (this.checkModelRotation() && this.checkModelPosition()) {
            cancelAnimationFrame(id);

            this.stopModelRotation(this.targetAngleX, this.targetAngleY, this.targetAngleZ, this.targetModelPosition);
        }
    };

    moveModelToTargetPosition() {
        if (this.checkModelPosition()) {
            return;
        }

        this.model.position.x += (this.targetModelPosition.x - this.model.position.x) * 0.1;
        this.model.position.y += (this.targetModelPosition.y - this.model.position.y) * 0.1;
        this.model.position.z += (this.targetModelPosition.z - this.model.position.z) * 0.1;
    }

    rotateModel() {
        if (this.checkModelRotation()) {
            return;
        }

        this.model.rotation.x += (this.targetAngleX - this.model.rotation.x) * 0.1;
        this.model.rotation.y += (this.targetAngleY - this.model.rotation.y) * 0.1;
        this.model.rotation.z += (this.targetAngleZ - this.model.rotation.z) * 0.1;
    }

    checkModelRotation() {
        return (
            toFloat(this.model.rotation.x) === toFloat(this.targetAngleX) &&
            toFloat(this.model.rotation.y) === toFloat(this.targetAngleY) &&
            toFloat(this.model.rotation.z) === toFloat(this.targetAngleZ)
        );
    }

    checkModelPosition() {
        return (
            toFloat(this.model.position.x) === toFloat(this.targetModelPosition.x) &&
            toFloat(this.model.position.y) === toFloat(this.targetModelPosition.y) &&
            toFloat(this.model.position.z) === toFloat(this.targetModelPosition.z)
        );
    }

    rotateTo(axisValue, degrees) {
        let axis;

        switch (axisValue) {
            case RotationAxis.X:
                axis = new THREE.Vector3(1, 0, 0);
                break;
            case RotationAxis.Y:
                axis = new THREE.Vector3(0, 1, 0);
                break;
            case RotationAxis.Z:
                axis = new THREE.Vector3(0, 0, 1);
                break;
            default:
                return;
        }
        const angle = degrees * (Math.PI / 180);
        this.model.rotateOnAxis(axis, angle);
        this.update();
    }

    zoomCamera(delta) {
        const newZoom = this.camera.zoom - delta * 0.002;
        this.camera.setZoom(newZoom);
        this.update();
    }

    saveCanvasAsImage = () => {
        this.transformControls.visible = false;
        this.renderer.setClearColor(0x000000, 0);
        this.update();
        const canvas = this.renderer.domElement;
        const image = canvas.toDataURL('image/png');
        this.image = image;

        this.transformControls.visible = true;
        this.update();
        return image;
    };

    update() {
        this.renderCameraViewport();
    }
}

export default Scene;
