export const DEFAULT_MODEL_NAME = 'Automotive Creative Mix';
export const DEFAULT_PROMPT = 'autocar';
export const DEFAULT_SEED_VALUE = 'Auto';
export const MODEL_NOT_FOUND_LABEL = 'Deleted';
export const INFO_NOT_FOUND_LABEL = '—';
export const DEFAULT_NEW_MODEL_NAME = 'NewModel';
export const DEFAULT_MERGE_MULTIPLIER = 50;
export const COMPLEX_LABEL_SEPARATOR = '*';
export const DEFAULT_NUMBER_OF_COMPLEX_LABEL_PARTS = 3;
export const DEFAULT_ASPECT_RATIO = 'auto';
export const DEFAULT_PLACEHOLDER_RATIO = '16/9';
export const DEFAULT_IMAGE_WEIGHT = 100;
export const DEFAULT_ERASER_SIZE = 15;
export const DEFAULT_NEGATIVE_PROMPT = '(((bad quality, worst quality, destructed, fractured, inconsistent)))';
export const DEFAULT_EXIT_BUTTON = 'Escape';
export const NEGATIVE_PROMPT_PLACEHOLDER = 'default';
export const ARROW_BUTTONS = {
    UP: 'ArrowUp',
    DOWN: 'ArrowDown',
    LEFT: 'ArrowLeft',
    RIGHT: 'ArrowRight',
};
export const IMAGE_MAX_SIZE_FOR_UPSCALE = 1600 * 900;
export const UPSCALE_WARNING_MESSAGE = 'Upscaling images larger than 1600 x 900 is not supported';
export const SELECT_LAYER_DEBOUNCE_TIME = 300;
export const DEFAULT_ZOOM_STEP = 0.3;

// External Paths
export const DRACO_DECODER_PATH = 'https://unpkg.com/three@latest/examples/jsm/libs/draco/gltf/';
